import React from "react"

import NewsletterItem from "../components/newsletteritem"
import { withPreview } from "gatsby-source-prismic"
import DynamicImage from "../utils/dynamicimage"

function NewsletterItemPreview({ data }) {
  return (
    <div className="pt-5">
      <div className="flex flex-col justify-center min-h-screen min-w-screen">
        <div className="container flex flex-col items-start justify-center px-5 mb-5 bg-mbSnow">
          <h2 className="mb-5 text-lg font-thin text-gray-300 cantarell">
            Card Preview
          </h2>
          <NewsletterItem
            border_active={false}
            shadow_active={false}
            animations_active={false}
            // defaults to bg-white
            background_color="bg-mbSnow"
            slug={data.prismicNewsletterIssue.uid}
            alt={data.prismicNewsletterIssue.data.issue_image?.alt}
            image_src={data.prismicNewsletterIssue.data.issue_image?.url}
            image_fluid={
              data.prismicNewsletterIssue.data.issue_image?.fluid
                ? data.prismicNewsletterIssue.data.issue_image.fluid
                : null
              // : !data.prismicNewsletterIssue.data.issue_image &&
              //   data.fallbackImage.data.default_main_image.childImageSharp
              //     .fluid
            }
            issue_link={data.prismicNewsletterIssue.data.issue_link?.url}
            publishing_date={data.prismicNewsletterIssue.data.publishing_date}
            node_id={data.prismicNewsletterIssue.data.id}
            title_text={data.prismicNewsletterIssue.data.issue_title?.text}
            excerpt_text={data.prismicNewsletterIssue.data.excerpt?.text}
            rounded_active={false}
            additionalClassNames="lg:w-1/3 lg:max-w-none min-w-full lg:min-w-0"
          />
        </div>

        <div className="px-5 pt-5 pb-10 bg-bs-300">
          <div className="container">
            <h2 className="mb-5 text-lg font-thin text-white cantarell">
              Newsletter Archive Preview
            </h2>
            <a
              key={data.prismicNewsletterIssue.data.id}
              href={data.prismicNewsletterIssue.data.issue_link?.url}
              target="_blank"
              rel="noreferrer"
              className="group"
              // data-sal="slide-up"
              // data-sal-duration="1000"
              // data-sal-delay="150"
              // data-sal-easing="ease"
            >
              {/* <p className="text-sm text-cb-500 sm:text-base lg:text-xl">{"01".toUpperCase()}</p> */}
              <div className="grid grid-cols-6 col-span-1 row-span-5 gap-2 px-0 py-0 pb-6 transition-colors duration-500 bg-white rounded-md shadow-lg lg:px-8 lg:py-8 grid-auto-rows lg:grid-rows-3 lg:gap-10 group-hover:bg-gray-100">
                <div className="w-full h-48 col-start-1 col-end-7 row-start-1 row-end-1 overflow-hidden lg:col-start-1 lg:col-end-4 lg:row-start-1 lg:row-end-4 rounded-t-md lg:rounded-t-none md:h-56 lg:h-full lg:overflow-visible">
                  <DynamicImage
                    className="min-w-full min-h-full"
                    placeholderClassName="w-full object-cover object-center"
                    style={{ maxHeight: "100%" }}
                    imgStyle={{
                      objectFit: "cover",
                      width: "100%",
                      objectPosition: "50% 50%",
                      maxHeight: "100%",
                    }}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    src={data.prismicNewsletterIssue.data.issue_image?.url}
                    fluid={
                      data.prismicNewsletterIssue.data.issue_image?.fluid
                        ? data.prismicNewsletterIssue.data.issue_image.fluid
                        : null
                    }
                    alt={data.prismicNewsletterIssue.data.issue_image?.alt}
                  />
                </div>
                <div className="flex flex-col h-full max-w-md col-start-1 col-end-7 row-start-2 row-end-2 px-3 pt-0 sm:pt-2 lg:pt-0 lg:px-0 md:px-6 lg:col-start-4 lg:col-end-7 lg:row-start-1 lg:row-end-4 min-w-md">
                  <h2 className="text-2xl font-medium leading-tight lg:text-3xl sm:leading-tight lg:leading-tight">
                    {data.prismicNewsletterIssue.data.issue_title?.text}
                  </h2>
                  <p className="text-sm font-thin text-gray-500 sm:text-base lg:text-base lg:pb-1">
                    {data.prismicNewsletterIssue.data.publishing_date}
                  </p>
                  <p className="mt-0 text-sm font-light text-gray-800 sm:text-base lg:text-lg">
                    {data.prismicNewsletterIssue.data.excerpt?.text}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withPreview(NewsletterItemPreview)

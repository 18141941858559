import React from "react"
import { withUnpublishedPreview } from "gatsby-source-prismic"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import SEO from "../components/seo"
import Layout from "../components/layout"

// Templates
import Post from "../templates/post"
import Privacy from "./privacy-policy"
import LegalNotice from "./legal-notice"
import Newsletter from "./newsletter"
import Home from "./index"
import Subscribe from "./subscribe"
import NewsletterItemPreview from "../templates/newsletteritem_preview"
import ExternalItemPreview from "../templates/externalitem_preview"
import { graphql } from "gatsby"

function NotFoundPage({ data }) {
  return (
    <Layout data={data}>
      <div id="main-container" className="h-screen bg-mbSnow">
        <SEO title="Not Found" description="This page doesn't exist." />
        <div
          className="flex flex-col items-center justify-center h-screen"
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-delay="500"
          data-sal-easing="ease"
        >
          <div className="flex flex-col items-center px-5">
            <h1 className="mt-10 mb-3 text-4xl leading-tight text-center sm:leading-tight lg:leading-tight md:text-5xl lg:text-6xl text-cb-900">
              404 - Not Found
            </h1>
            <p className="max-w-2xl text-lg text-center text-gray-800 sm:text-xl md:px-20">
              This page hasn't been found.
              <br />
              Please check the URL, go to the home page or use the menu to find
              what you are looking for.
            </p>
          </div>
          <div className="flex items-end justify-center w-full">
            <div className="flex justify-around mt-12 mb-16">
              <AniLink to="/" cover direction="left" bg="#FFFBFA">
                <div className="flex items-center px-5 py-4 text-gray-600 group">
                  <span className="text-xl transition-colors duration-500 lg:text-2xl 3xl:text-3xl group-hover:text-gray-900">
                    Go to 'Home'
                  </span>
                  <svg
                    className="w-8 h-8 ml-2 transition-colors transition-transform duration-500 transform md:w-10 md:h-10 3xl:w-16 3xl:h-16 text-cb-900 group-hover:text-cb-500 group-hover:translate-x-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={0.8}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </AniLink>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    prismicSiteSettings {
      data {
        cookie_banner_text {
          text
        }
        main_menu_links {
          text {
            text
          }
          link {
            url
          }
        }
      }
    }
    allPrismicExternalArticle {
      edges {
        node {
          __typename
          id
          tags
          data {
            publisher
            date
            title {
              text
            }
            excerpt {
              text
            }
            article_link {
              url
            }
            image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    allPrismicPodcastEpisode {
      edges {
        node {
          __typename
          id
          tags
          data {
            publisher
            date
            title {
              text
            }
            excerpt {
              text
            }
            article_link {
              url
            }
            image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }

    allPrismicNewsletterIssue(
      sort: { order: DESC, fields: data___publishing_date }
      limit: 3
    ) {
      edges {
        node {
          __typename
          uid
          id
          data {
            issue_title {
              text
            }
            excerpt {
              text
            }
            publishing_date(formatString: "DD.MM.YYYY")
            issue_link {
              url
            }
            issue_image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    blog_post: Post,
    privacy: Privacy,
    legal_notice: LegalNotice,
    newsletter: Newsletter,
    home: Home,
    subscribe: Subscribe,
    newsletter_issue: NewsletterItemPreview,
    external_article: ExternalItemPreview,
  },
})

import React from "react"

import ExternalItem from "../components/externalitem"
import { withPreview } from "gatsby-source-prismic"
import DynamicImage from "../utils/dynamicimage"

function ExternalArticlePreview({ data }) {
  return (
    <div className="py-10">
      <div className="flex flex-col justify-center min-h-screen px-5 min-w-screen">
        <div className="container flex flex-col items-center justify-center mb-5 bg-mbSnow">
          <h2 className="w-full mb-5 text-lg font-thin text-gray-300 cantarell">
            Card Preview
          </h2>
          <div className="container grid grid-cols-1 col-start-1 col-end-12 gap-4 lg:gap-4 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5">
            <ExternalItem
              animation_active={false}
              border_active={false}
              article_url={data.prismicExternalArticle.data.article_link.url}
              image_src={data.prismicExternalArticle.data.image.url}
              image_fluid={
                data.prismicExternalArticle.data.image &&
                data.prismicExternalArticle.data.image.fluid
                  ? data.prismicExternalArticle.data.image.fluid
                  : null
              }
              alt={data.prismicExternalArticle.data.image.alt}
              node_id={data.prismicExternalArticle.data.id}
              tags={data.prismicExternalArticle.tags}
              title_text={data.prismicExternalArticle.data.title.text}
              excerpt_text={data.prismicExternalArticle.data.excerpt.text}
              publishing_date={data.prismicExternalArticle.data.date}
              publisher={data.prismicExternalArticle.data.publisher}
            />
          </div>
        </div>

        <div className="py-10 bg-mbSnow">
          <div className="container">
            <h2 className="mb-5 text-lg font-thin text-gray-300 cantarell">
              Writing Preview
            </h2>
            <a
              key={data.prismicExternalArticle.data.id}
              href={data.prismicExternalArticle.data.article_link.url}
              target="_blank"
              rel="noreferrer"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="150"
              data-sal-easing="ease"
            >
              {/* <p className="text-sm text-cb-500 sm:text-base lg:text-xl">{data.prismicExternalArticle.data.publisher.toUpperCase()}</p> */}
              <div className="grid grid-cols-6 col-span-1 row-span-5 gap-2 px-0 py-0 pb-6 transition-colors duration-500 bg-white rounded-md shadow-lg lg:px-8 lg:py-8 grid-auto-rows lg:grid-rows-3 lg:gap-10 hover:bg-gray-100">
                <div className="w-full h-48 col-start-1 col-end-7 row-start-1 row-end-1 overflow-hidden lg:col-start-1 lg:col-end-4 lg:row-start-1 lg:row-end-4 rounded-t-md lg:rounded-t-none lg:h-full lg:overflow-visible">
                  <DynamicImage
                    className="min-w-full min-h-full"
                    placeholderClassName="w-full object-cover object-center"
                    style={{ maxHeight: "100%" }}
                    imgStyle={{
                      objectFit: "cover",
                      width: "100%",
                      objectPosition: "50% 50%",
                    }}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    src={
                      !data.prismicExternalArticle.data.image.fluid &&
                      data.prismicExternalArticle.data.image.url
                    }
                    fluid={
                      data.prismicExternalArticle.data.image.fluid
                        ? data.prismicExternalArticle.data.image.fluid
                        : undefined
                    }
                    alt={data.prismicExternalArticle.data.image.alt}
                  />
                </div>
                <div className="flex flex-col h-full max-w-md col-start-1 col-end-7 row-start-2 row-end-2 px-3 pt-0 md:px-6 sm:pt-2 lg:pt-0 lg:px-0 lg:col-start-4 lg:col-end-7 lg:row-start-1 lg:row-end-4 min-w-md">
                  <p className="text-xs font-light text-cb-500 sm:text-sm lg:text-lg">
                    {data.prismicExternalArticle.data.publisher &&
                      data.prismicExternalArticle.data.publisher.toUpperCase()}
                  </p>
                  <h2 className="text-xl font-medium leading-tight sm:text-2xl lg:text-3xl sm:leading-tight lg:leading-tight">
                    {data.prismicExternalArticle.data.title &&
                      data.prismicExternalArticle.data.title.text}
                  </h2>
                  <p className="mt-1 text-sm font-light text-gray-800 sm:text-base lg:text-lg">
                    {data.prismicExternalArticle.data.excerpt &&
                      data.prismicExternalArticle.data.excerpt.text}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withPreview(ExternalArticlePreview)

import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import BackgroundImage from "gatsby-background-image"
import Form from "../components/form"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query {
    prismicSubscribe {
      data {
        header_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        subscribe_text {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
      }
    }
  }
`

function Subscribe({ data }) {
  const { subscribe_text } = data.prismicSubscribe.data
  return (
    <>
      <SEO
        title={data.prismicSubscribe.data.seo_title?.text}
        description={data.prismicSubscribe.data.seo_description?.text}
      />
      <BackgroundImage
        id="main-container"
        className="min-h-screen bg-top sm:h-screen"
        style={{ backgroundPosition: "top" }}
        // fluid={data.prismicSubscribe.data.header_image.fluid}
        // fluid={data.header_image.childImageSharp.fluid}
        fluid={
          data.prismicSubscribe.data.header_image?.localFile?.childImageSharp
            ?.fluid
        }
      >
        <div
          className="container grid min-h-screen grid-cols-12 section-1 grid-auto-rows"
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-delay="500"
          data-sal-easing="ease"
        >
          <div className="flex flex-col items-center col-start-2 col-end-12 row-start-1 row-end-4 intro">
            <h1 className="mt-2 mb-2 text-4xl leading-tight text-center sm:mb-6 sm:mt-12 sm:leading-tight lg:leading-tight ogg sm:text-5xl text-cb-900 lg:mt-10 xl:mt-16 2xl:mt-48">
              Paige McClanahan
            </h1>
            <p className="max-w-2xl text-base text-center text-gray-800 sm:text-base">
              {subscribe_text.text}
            </p>
            <Form autoFocus="autoFocus" />
          </div>
          <div className="flex items-end justify-center w-full col-start-1 col-end-13 row-start-6">
            <nav className="flex justify-center w-full py-3 text-gray-800 lg:text-lg">
              <div className="z-50 inline-block mr-3 transition-colors duration-75 ease-in lg:mr-5 hover:text-gray-900">
                <AniLink
                  to="/privacy-policy"
                  cover
                  direction="left"
                  bg="#FFFBFA"
                >
                  Privacy
                </AniLink>
              </div>
              <div className="z-50 inline-block transition-colors duration-75 ease-in hover:text-gray-900">
                <AniLink to="/legal-notice" cover direction="left" bg="#FFFBFA">
                  Legal Notice
                </AniLink>
              </div>
            </nav>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default withPreview(Subscribe)

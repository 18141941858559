// Packages
import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import AniLink from "gatsby-plugin-transition-link"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/form"
// Utils
import DynamicImage from "../utils/dynamicimage"

// Visual Elements
import { Twitter, Facebook, Reddit } from "@icons-pack/react-simple-icons"

export const query = graphql`
  query ($uid: String!) {
    prismicSiteSettings {
      data {
        cookie_banner_text {
          text
        }
        main_menu_links {
          text {
            text
          }
          link {
            url
          }
        }
      }
    }
    prismicBlogPost(uid: { eq: $uid }) {
      url
      data {
        date(formatString: "D MMMM YYYY")
        title {
          text
        }
        subtitle {
          text
        }
        main_image {
          url
          fluid(maxWidth: 900) {
            ...GatsbyPrismicImageFluid
          }
        }
        main_image_caption {
          text
        }
        main_image_attribution {
          text
        }
        excerpt {
          text
        }
        body {
          __typename
          ... on PrismicBlogPostBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyPullQuote {
            id
            slice_type
            primary {
              quote_text {
                text
              }
              author {
                text
              }
            }
          }
          ... on PrismicBlogPostBodyImageWithCaption {
            id
            slice_type
            primary {
              body_image {
                url
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              image_caption {
                text
              }
              image_attribution {
                text
              }
            }
          }
          ... on PrismicBlogPostBodySubscribeForm {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
          }
          ... on PrismicBlogPostBodyLinkedObjectsList {
            id
            slice_type
            primary {
              list_name {
                text
              }
            }
            items {
              linked_object {
                document {
                  __typename
                  ... on PrismicBlogPost {
                    id
                    url

                    data {
                      title {
                        text
                      }
                      main_image {
                        url
                        fluid(maxWidth: 400) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                    }
                  }
                  ... on PrismicExternalArticle {
                    data {
                      title {
                        text
                      }
                      image {
                        url
                        fluid(maxWidth: 400) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      article_link {
                        url
                      }
                    }
                  }
                  ... on PrismicNewsletterIssue {
                    id
                    data {
                      issue_title {
                        text
                      }
                      issue_link {
                        url
                      }
                      issue_image {
                        url
                        fluid(maxWidth: 400) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

function Post({ data }) {
  // Post Body - Item List Slice Switch
  function itemSwitch(currentItemList) {
    return currentItemList.map(item => {
      switch (item.linked_object.document?.__typename) {
        case "PrismicBlogPost":
          return (
            <div
              className="px-0 py-3 mx-0 transition-colors duration-500 sm:mb-2 sm:px-5 sm:-mx-10 hover:bg-gray-100"
              key={item.id}
            >
              <AniLink
                className="flex flex-col sm:items-stretch sm:justify-start sm:flex-row"
                to={item.linked_object.document?.url}
              >
                <div className="flex-shrink-0 h-32 mb-2 overflow-hidden sm:w-48 sm:mb-0 sm:mr-3">
                  <DynamicImage
                    className="object-cover object-center min-w-full min-h-full"
                    placeholderClassName="w-full object-cover object-center"
                    style={{ maxHeight: "100%" }}
                    imgStyle={{
                      objectFit: "cover",
                      width: "100%",
                      objectPosition: "50% 50%",
                    }}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    src={
                      item.linked_object.document.data.main_image &&
                      item.linked_object.document.data.main_image.url
                    }
                    fluid={
                      item.linked_object.document.data.main_image &&
                      item.linked_object.document.data.main_image.fluid
                        ? item.linked_object.document.data.main_image.fluid
                        : undefined
                    }
                  />
                </div>
                <div className="flex-grow-0">
                  <h4 className="leading-tight no-underline sm:leading-tight lg:leading-tight">
                    {item.linked_object.document.data.title &&
                      item.linked_object.document.data.title.text}
                  </h4>
                </div>
              </AniLink>
            </div>
          )
        case "PrismicExternalArticle":
          return (
            <div
              className="px-0 py-3 mx-0 transition-colors duration-500 sm:mb-2 sm:px-5 sm:-mx-10 hover:bg-gray-100"
              key={item.id}
            >
              <a
                className="flex flex-col sm:items-stretch sm:justify-start sm:flex-row"
                href={item.linked_object.document.data.article_link.url}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex-shrink-0 h-32 mb-2 overflow-hidden sm:w-48 sm:mb-0 sm:mr-3">
                  <DynamicImage
                    className="object-cover object-center min-w-full min-h-full"
                    placeholderClassName="w-full object-cover object-center"
                    imgStyle={{
                      objectFit: "cover",
                      width: "100%",
                      objectPosition: "50% 50%",
                    }}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    src={item.linked_object.document.data.image.url}
                    fluid={
                      item.linked_object.document.data.image &&
                      item.linked_object.document.data.image.fluid
                        ? item.linked_object.document.data.image.fluid
                        : undefined
                    }
                  />
                </div>
                <div className="flex-grow-0">
                  <h4 className="no-underline leading-tightt sm:leading-tight lg:leading-tight">
                    {item.linked_object.document.data.title &&
                      item.linked_object.document.data.title.text}
                  </h4>
                </div>
              </a>
            </div>
          )
        case "PrismicNewsletterIssue":
          return (
            <div
              className="px-0 py-3 mx-0 transition-colors duration-500 sm:mb-2 sm:px-5 sm:-mx-10 hover:bg-gray-100"
              key={item.id}
            >
              <a
                className="flex flex-col sm:items-stretch sm:justify-start sm:flex-row"
                href={item.linked_object.document.data.issue_link.url}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex-shrink-0 h-32 mb-2 overflow-hidden sm:w-48 sm:mb-0 sm:mr-3">
                  <DynamicImage
                    className="object-cover object-center min-w-full min-h-full"
                    placeholderClassName="w-full object-cover object-center"
                    imgStyle={{
                      objectFit: "cover",
                      width: "100%",
                      objectPosition: "50% 50%",
                    }}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    src={
                      item.linked_object.document.data.issue_image &&
                      item.linked_object.document.data.issue_image.url
                    }
                    fluid={
                      item.linked_object.document.data.issue_image &&
                      item.linked_object.document.data.issue_image.fluid
                        ? item.linked_object.document.data.issue_image.fluid
                        : undefined
                    }
                  />
                </div>
                <div className="flex-grow-0">
                  <h4 className="leading-tight no-underline sm:leading-tight lg:leading-tight">
                    {item.linked_object.document.data.issue_title &&
                      item.linked_object.document.data.issue_title.text}
                  </h4>
                </div>
              </a>
            </div>
          )
        default:
          return null
      }
    })
  }

  // Post Body - Slice Array
  const sliceArray = data.prismicBlogPost.data.body.map(slice => {
    switch (slice.slice_type) {
      case "text":
        return (
          <div
            className="post-html"
            key={slice.id}
            dangerouslySetInnerHTML={{
              __html: slice.primary.text.html,
            }}
          />
        )
      case "image_with_caption":
        return (
          <div
            className="flex flex-col py-8 -mx-2 sm:-mx-3 md:-mx-5 lg:-mx-16"
            key={slice.id}
          >
            <DynamicImage
              className="mb-0 sm:mb-1"
              placeholderClassName="w-full object-cover object-center"
              imgStyle={{
                objectFit: "cover",
                width: "100%",
                objectPosition: "50% 50%",
              }}
              objectFit="cover"
              objectPosition="50% 50%"
              src={slice.primary.body_image.url}
              fluid={
                slice.primary.body_image && slice.primary.body_image.fluid
                  ? slice.primary.body_image.fluid
                  : undefined
              }
            />
            <figcaption className="font-medium leading-none sm:leading-none lg:leading-none">
              <span className="text-xs font-medium leading-none text-gray-700 lg:leading-none sm:leading-none sm:text-sm">
                {slice.primary.image_caption &&
                  slice.primary.image_caption.text}
              </span>{" "}
              {/* <br /> */}
              <span className="font-light leading-tight text-gray-600 text-xxs sm:leading-tight lg:leading-tight sm:text-xs lg:text-xs">
                {slice.primary.image_attribution &&
                  slice.primary.image_attribution.text}
              </span>
            </figcaption>
          </div>
        )
      case "pull_quote":
        return (
          <div className="py-5 md:py-8 md:px-3 pull-quote" key={slice.id}>
            <p className="mb-1 text-xl leading-tight text-gray-900 sm:leading-tight lg:leading-tight md:text-2xl">
              "{slice.primary.quote_text.text}"
            </p>
            <p className="text-lg leading-tight text-gray-700 sm:leading-tight lg:leading-tight md:text-xl">
              {" "}
              - {slice.primary.author.text}
            </p>
          </div>
        )
      case "linked_objects_list":
        return (
          <div className="py-5 mx-auto max-w-xxs sm:max-w-none" key={slice.id}>
            <h3 className="mb-2 text-base leading-tight text-gray-800 sm:leading-tight lg:leading-tight lg:text-lg">
              {slice.primary.list_name.text}
            </h3>
            <div className="">{itemSwitch(slice.items)}</div>
          </div>
        )
      case "subscribe_form":
        return (
          <div className="py-5 sm:max-w-none" key={slice.id}>
            <h3 className="mb-1 text-base leading-tight text-gray-800 sm:leading-tight lg:leading-tight lg:mb-2 lg:text-lg">
              {slice.primary.heading.text}
            </h3>
            <Form isInPost={true} />
          </div>
        )
      default:
        return undefined
    }
  })

  return (
    <Layout data={data}>
      <div id="main-container" className="bg-mbSnow">
        <SEO
          title={
            data.prismicBlogPost.data.seo_title?.text ||
            data.prismicBlogPost.data.title.text
          }
          description={
            data.prismicBlogPost.data.seo_description?.text ||
            data.prismicBlogPost.data.excerpt?.text
          }
          image={data.prismicBlogPost.data.main_image.fluid.src}
        />
        <article className="grid min-h-screen grid-cols-12 px-2 pb-16 mx-auto pt-14 sm:pt-14 md:pt-24 lg:max-w-5xl post grid-auto-rows bg-mbSnow">
          <div className="col-start-2 col-end-12 lg:col-start-4 lg:col-end-4 controls">
            <AniLink
              to="/clips"
              className="transition-colors duration-300 focus:outline-none hover:text-cb-500"
              cover
              direction="left"
              bg="#4B9CD3"
              //FFFBFA 4B9CD3
            >
              <svg
                className="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={0.5}
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
            </AniLink>
          </div>
          <div className="col-start-2 col-end-12 mb-5 lg:col-start-4 lg:col-end-10">
            <h1 className="text-2xl leading-tight sm:leading-tight lg:leading-tight sm:text-3xl lg:text-4xl">
              {data.prismicBlogPost.data.title.text}
            </h1>
            {data.prismicBlogPost.data.subtitle && (
              <p className="pt-2 pb-3 text-base font-thin leading-tight text-gray-800 sm:leading-tight lg:leading-tight sm:text-lg lg:text-xl">
                {data.prismicBlogPost.data.subtitle.text}
              </p>
            )}
            <p className="text-sm font-thin text-gray-500 lg:text-lg">
              {data.prismicBlogPost.data.date}
            </p>
          </div>
          <div className="w-full col-start-2 col-end-12 mb-5 lg:col-start-3 lg:col-end-11">
            <DynamicImage
              className="object-cover mb-0 sm:mb-1"
              placeholderClassName="w-full object-cover object-center"
              imgStyle={{
                objectFit: "cover",
                width: "100%",
                objectPosition: "50% 50%",
              }}
              objectFit="cover"
              objectPosition="50% 50%"
              src={data.prismicBlogPost.data.main_image.url}
              fluid={
                data.prismicBlogPost.data.main_image &&
                data.prismicBlogPost.data.main_image.fluid
                  ? data.prismicBlogPost.data.main_image.fluid
                  : undefined
              }
            />
            <figcaption>
              {data.prismicBlogPost.data.main_image_caption && (
                <span className="text-xs font-medium leading-none text-gray-700 lg:leading-none sm:leading-none sm:text-sm">
                  {data.prismicBlogPost.data.main_image_caption.text}
                </span>
              )}{" "}
              {data.prismicBlogPost.data.main_image_attribution && (
                <span className="font-light leading-tight text-gray-600 text-xxs sm:leading-tight lg:leading-tight sm:text-xs lg:text-xs">
                  {data.prismicBlogPost.data.main_image_attribution.text}
                </span>
              )}
            </figcaption>
          </div>

          {/* Post Body - Content Slices */}

          {/* <div
            className="col-start-2 col-end-12 row-start-4 text-xl font-light lg:col-start-4 lg:col-end-10 post-html"
            dangerouslySetInnerHTML={{
              __html: data.prismicBlogPost.data.content.html,
            }}
          /> */}
          <div className="col-start-2 col-end-12 text-xl font-light lg:col-start-4 lg:col-end-10">
            {sliceArray}
          </div>

          {/* Post Share */}
          <div className="w-full col-start-2 col-end-12 mt-10 lg:col-start-4 lg:col-end-10">
            <div className="flex items-center">
              <hr className="flex-auto mr-3 border-gray-400" />
              <span className="text-gray-400">share</span>
              <hr className="flex-auto ml-3 border-gray-400" />
            </div>
            <div className="flex justify-center w-full gap-10 pt-10 mx-auto lg:w-3/4 lg:gap-10">
              <a
                href={`http://www.reddit.com/submit?url=https://paigemcclanahan.com/blog${data.prismicBlogPost.url}`}
                className="flex items-center justify-center w-1/3"
                target="_blank"
                rel="noreferrer"
              >
                <Reddit className="text-gray-500 transition-colors duration-300 hover:text-cb-500" />
              </a>

              <a
                href={`https://twitter.com/intent/tweet?url=https://paigemcclanahan.com/blog${data.prismicBlogPost.url}`}
                className="flex items-center justify-center w-1/3"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter className="text-gray-500 transition-colors duration-300 hover:text-cb-500" />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=https://paigemcclanahan.com/blog${data.prismicBlogPost.url}`}
                className="flex items-center justify-center w-1/3"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook className="text-gray-500 transition-colors duration-300 hover:text-cb-500" />
              </a>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default withPreview(Post)

import React, { useState } from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import SEO from "../components/seo"
import Layout from "../components/layout"

export const query = graphql`
  query {
    prismicSiteSettings {
      data {
        cookie_banner_text {
          text
        }
        main_menu_links {
          text {
            text
          }
          link {
            url
          }
        }
      }
    }
    prismicPrivacy {
      data {
        page_title {
          text
        }
        content {
          html
        }
        page_title_fr {
          text
        }
        content_fr {
          html
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
      }
    }
  }
`

function PrivacyPage({ data }) {
  // Language Control
  const [isFrench, toggleLanguage] = useState(false)

  const page_title_default = "Privacy Policy"
  const page_title_fr_default = "Politique de Confidentialité"

  return (
    <Layout data={data}>
      <div id="main-container" className="bg-mbSnow">
        <SEO
          title={data.prismicPrivacy.data.seo_title?.text}
          description={data.prismicPrivacy.data.seo_description?.text}
        />
        <div className="grid min-h-screen grid-cols-12 px-2 pb-16 mx-auto lg:max-w-5xl post grid-auto-rows bg-mbSnow">
          <div className="container flex flex-col items-center col-start-2 col-end-12 pt-10 lg:pt-16">
            <div className="flex gap-2 cantarell">
              <button
                className={`rounded-sm focus:outline-none px-3 transition-colors duration-300 mb-3 ${
                  isFrench
                    ? "text-gray-600 border-b-2 border-transparent"
                    : "text-cb-500 border-b-2 border-cb-500"
                }`}
                onClick={() => toggleLanguage(() => (isFrench ? false : false))}
                aria-label="change language to English"
              >
                English
              </button>

              <button
                className={`rounded-sm focus:outline-none px-3 transition-colors duration-300 mb-3 ${
                  isFrench
                    ? "text-cb-500 border-b-2 border-cb-500"
                    : "text-gray-600 border-b-2 border-transparent"
                }`}
                onClick={() => toggleLanguage(() => (isFrench ? true : true))}
                aria-label="change language to French"
              >
                Français
              </button>
            </div>
            <h1 className="pt-8 text-2xl leading-tight text-center sm:leading-tight lg:leading-tight sm:text-3xl lg:text-4xl lg:pt-5 lg:pb-3">
              {/* {data.prismicPrivacy.data} */}
              {isFrench
                ? data.prismicPrivacy.data.page_title_fr?.text ||
                  page_title_fr_default
                : data.prismicPrivacy.data.page_title?.text ||
                  page_title_default}
            </h1>
          </div>
          <div
            id="privacy-policy-body"
            className="container col-start-2 col-end-12 row-start-4 text-xl font-light lg:col-start-4 lg:col-end-10 post-html"
            dangerouslySetInnerHTML={
              isFrench
                ? {
                    __html: data.prismicPrivacy.data.content_fr.html,
                  }
                : {
                    __html: data.prismicPrivacy.data.content.html,
                  }
            }
          />
        </div>
      </div>
    </Layout>
  )
}

export default withPreview(PrivacyPage)
